/* App.svelte generated by Svelte v3.23.0 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_block,
	detach,
	element,
	globals,
	init,
	insert,
	noop,
	null_to_empty,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	update_keyed_each
} from "svelte/internal";

const { document: document_1 } = globals;
import { onMount } from "svelte";
import jQuery from "jquery";

function add_css() {
	var style = element("style");
	style.id = "svelte-1umqyk2-style";
	style.textContent = "#wrapper.svelte-1umqyk2.svelte-1umqyk2{position:relative;width:100%;height:100%;background:#111416;display:flex;align-items:center;justify-content:center;padding-left:15px;padding-right:15px}#wrapper.svelte-1umqyk2 #inner.svelte-1umqyk2{width:100%;max-width:550px;text-align:center}#wrapper.svelte-1umqyk2 #inner a.svelte-1umqyk2{color:#de8d14}#wrapper.svelte-1umqyk2 #inner .progress-bar-success.svelte-1umqyk2{color:#222;font-weight:bold;background:#fbb040}#wrapper.svelte-1umqyk2 #inner #logo-wrapper.svelte-1umqyk2{display:flex;margin:0 auto;align-items:center;justify-content:center;flex-direction:column}#wrapper.svelte-1umqyk2 #inner h1.svelte-1umqyk2{font-size:25px;color:#fbb040;margin-bottom:20px}#wrapper.svelte-1umqyk2 #inner #result.svelte-1umqyk2{width:100%;padding:5rem 3rem;background:#fff;border-radius:15px;margin-top:0.5rem}#wrapper.svelte-1umqyk2 #inner #result .progress.svelte-1umqyk2{margin:40px 0}#wrapper.svelte-1umqyk2 #inner #result h2.svelte-1umqyk2{font-size:23px}#wrapper.svelte-1umqyk2 #inner #result p.svelte-1umqyk2{margin-bottom:3rem}#wrapper.svelte-1umqyk2 #inner #copyright.svelte-1umqyk2{font-size:12px;color:#777;padding:1.3rem 0}";
	append(document_1.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

// (231:3) {:else}
function create_else_block(ctx) {
	let h2;
	let t1;
	let p;
	let t3;
	let table;
	let tbody;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_value = /*rows*/ ctx[2].filter(func);
	const get_key = ctx => /*i*/ ctx[13];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			h2 = element("h2");
			h2.textContent = `${/*trans*/ ctx[3]("일부 도메인 접속불가", "Some connection problems")}`;
			t1 = space();
			p = element("p");
			p.textContent = "비디오스튜는 다양한 서버를 사용하여 서비스를 제공하고\n\t\t\t\t\t있습니다. 하지만 귀하의 네트워크에서 아래의 서버들에 접속이\n\t\t\t\t\t금지된 상태입니다. 컴퓨터나 공유기의 방화벽 설정을\n\t\t\t\t\t확인해주시거나, 회사인 경우 네트워크 관리자에게 아래의\n\t\t\t\t\t리스트에 대해 허용 문의해주세요.";
			t3 = space();
			table = element("table");
			tbody = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h2, "class", "text-danger svelte-1umqyk2");
			set_style(h2, "margin-bottom", "1em");
			attr(p, "class", "text-danger svelte-1umqyk2");
			set_style(p, "text-align", "left");
			attr(table, "class", "table table-condensed");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, p, anchor);
			insert(target, t3, anchor);
			insert(target, table, anchor);
			append(table, tbody);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(tbody, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 4) {
				const each_value = /*rows*/ ctx[2].filter(func);
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, tbody, destroy_block, create_each_block, null, get_each_context);
			}
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(p);
			if (detaching) detach(t3);
			if (detaching) detach(table);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

// (221:29) 
function create_if_block_1(ctx) {
	let h2;
	let t1;
	let a;

	return {
		c() {
			h2 = element("h2");
			h2.textContent = `${/*trans*/ ctx[3]("모든 환경 정상작동", "All features are working well!")}`;
			t1 = space();
			a = element("a");
			a.textContent = `${/*trans*/ ctx[3]("비디오스튜 접속하기", "Good to go videostew.com")} >>`;
			attr(h2, "class", "svelte-1umqyk2");
			attr(a, "href", "https://videostew.com");
			attr(a, "class", "btn btn-link btn-lg svelte-1umqyk2");
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			insert(target, a, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			if (detaching) detach(a);
		}
	};
}

// (208:3) {#if progress < 100}
function create_if_block(ctx) {
	let div1;
	let div0;
	let t0;
	let t1;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			t0 = text(/*progress*/ ctx[1]);
			t1 = text("%");
			attr(div0, "class", "progress-bar progress-bar-success svelte-1umqyk2");
			attr(div0, "role", "progressbar");
			set_style(div0, "width", /*progress*/ ctx[1] + "%");
			attr(div0, "aria-valuenow", /*progress*/ ctx[1]);
			attr(div0, "aria-valuemin", "0");
			attr(div0, "aria-valuemax", "100");
			attr(div1, "class", "progress svelte-1umqyk2");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, t0);
			append(div0, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*progress*/ 2) set_data(t0, /*progress*/ ctx[1]);

			if (dirty & /*progress*/ 2) {
				set_style(div0, "width", /*progress*/ ctx[1] + "%");
			}

			if (dirty & /*progress*/ 2) {
				attr(div0, "aria-valuenow", /*progress*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

// (253:9) {:else}
function create_else_block_1(ctx) {
	let span;
	let t_value = /*row*/ ctx[11].status + "";
	let t;
	let span_class_value;

	return {
		c() {
			span = element("span");
			t = text(t_value);
			attr(span, "class", span_class_value = "" + (null_to_empty(`label label-${/*row*/ ctx[11].status === "done" ? "success" : "danger"}`) + " svelte-1umqyk2"));
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t);
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 4 && t_value !== (t_value = /*row*/ ctx[11].status + "")) set_data(t, t_value);

			if (dirty & /*rows*/ 4 && span_class_value !== (span_class_value = "" + (null_to_empty(`label label-${/*row*/ ctx[11].status === "done" ? "success" : "danger"}`) + " svelte-1umqyk2"))) {
				attr(span, "class", span_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

// (251:9) {#if row.status === ''}
function create_if_block_2(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (img.src !== (img_src_value = "/ajax-loader.gif")) attr(img, "src", img_src_value);
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (244:6) {#each rows.filter((r) => r.status === 'fail') as row, i (i)}
function create_each_block(key_1, ctx) {
	let tr;
	let td0;
	let t0_value = /*i*/ ctx[13] + 1 + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*row*/ ctx[11].dispUrl + "";
	let t2;
	let t3;
	let td2;
	let t4;

	function select_block_type_1(ctx, dirty) {
		if (/*row*/ ctx[11].status === "") return create_if_block_2;
		return create_else_block_1;
	}

	let current_block_type = select_block_type_1(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		key: key_1,
		first: null,
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			if_block.c();
			t4 = space();
			attr(td1, "class", "text-left");
			attr(td2, "class", "text-center");
			this.first = tr;
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			if_block.m(td2, null);
			append(tr, t4);
		},
		p(ctx, dirty) {
			if (dirty & /*rows*/ 4 && t0_value !== (t0_value = /*i*/ ctx[13] + 1 + "")) set_data(t0, t0_value);
			if (dirty & /*rows*/ 4 && t2_value !== (t2_value = /*row*/ ctx[11].dispUrl + "")) set_data(t2, t2_value);

			if (current_block_type === (current_block_type = select_block_type_1(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(td2, null);
				}
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			if_block.d();
		}
	};
}

function create_fragment(ctx) {
	let div4;
	let div3;
	let div0;
	let t2;
	let div1;
	let t3;
	let div2;

	function select_block_type(ctx, dirty) {
		if (/*progress*/ ctx[1] < 100) return create_if_block;
		if (/*failCount*/ ctx[0] === 0) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			div4 = element("div");
			div3 = element("div");
			div0 = element("div");
			div0.innerHTML = `<h1 class="svelte-1umqyk2">VideoStew <span>Connection Check</span></h1>`;
			t2 = space();
			div1 = element("div");
			if_block.c();
			t3 = space();
			div2 = element("div");

			div2.textContent = `
			Videostew.‌ © 2014 - ${+new Date().getFullYear()}. All rights
			Reserved.
		`;

			attr(div0, "id", "logo-wrapper");
			attr(div0, "class", "svelte-1umqyk2");
			attr(div1, "id", "result");
			attr(div1, "class", "svelte-1umqyk2");
			attr(div2, "id", "copyright");
			attr(div2, "class", "svelte-1umqyk2");
			attr(div3, "id", "inner");
			attr(div3, "class", "svelte-1umqyk2");
			attr(div4, "id", "wrapper");
			attr(div4, "class", "svelte-1umqyk2");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, div3);
			append(div3, div0);
			append(div3, t2);
			append(div3, div1);
			if_block.m(div1, null);
			append(div3, t3);
			append(div3, div2);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div1, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			if_block.d();
		}
	};
}

function loadScript(url, resolve, reject) {
	var script = document.createElement("script");
	script.type = "text/javascript";

	if (script.readyState) {
		script.onreadystatechange = function () {
			if (script.readyState == "loaded" || script.readyState == "complete") {
				script.onreadystatechange = null;
				resolve();
			} else {
				reject();
			}
		};
	} else {
		script.onload = function () {
			resolve();
		};

		script.onerror = function () {
			reject();
		};
	}

	script.src = url;
	document.getElementsByTagName("head")[0].appendChild(script);
}

const func = r => r.status === "fail";

function instance($$self, $$props, $$invalidate) {
	const channelName = "connection-test-" + Math.random().toString().replace(".", "");
	const triggerPusherEndpoint = "https://videostew.com/tools/connection-test/" + channelName;
	const lang = navigator.language || navigator.userLanguage;
	let failCount = 0; //a
	let progress = 0;

	const rows = [
		{
			name: "videostew server",
			dispUrl: "videostew.com",
			endpoint: "https://videostew.com/info/health-check",
			status: "",
			type: "html"
		},
		{
			name: "aws couldfront",
			dispUrl: "cdn.videostew.com",
			endpoint: "https://cdn.videostew.com/js/jquery-3.6.0.min.js",
			status: "",
			type: "js"
		},
		{
			name: "aws lambda",
			dispUrl: "lambda-url.ap-northeast-2.on.aws",
			endpoint: "https://v4kfr6yy4wbvjqcm3evclagv6q0bokqj.lambda-url.ap-northeast-2.on.aws",
			status: "",
			type: "json"
		},
		{
			name: "stock pexels",
			dispUrl: "images.pexels.com",
			endpoint: "https://images.pexels.com/videos/1526909/pictures/preview-0.jpg",
			status: "",
			type: "json"
		},
		{
			name: "stock pixabay",
			dispUrl: "cdn.pixabay.com",
			endpoint: "https://cdn.pixabay.com/photo/2013/10/15/09/12/flower-195893_150.jpg",
			status: "",
			type: "json"
		},
		{
			name: "pusher receive",
			dispUrl: "pusher binding",
			endpoint: "",
			status: "",
			type: ""
		}
	];

	const calculateprogress = () => {
		const count = rows.length;
		const checked = rows.filter(r => r.status !== "").length;
		const doneCount = rows.filter(r => r.status === "done").length;
		$$invalidate(0, failCount = count - doneCount);
		$$invalidate(1, progress = Math.ceil(checked / count * 100));
	};

	const resultStatus = (index, status) => {
		$$invalidate(2, rows[index].status = status, rows);
		calculateprogress();
	};

	const trans = (ko, en) => {
		return lang === "ko" ? ko : en;
	};

	const findPusherRow = () => {
		return rows.find(r => r.name === "pusher receive");
	};

	const findPusherIndex = () => {
		return rows.findIndex(r => r.name === "pusher receive");
	};

	onMount(() => {
		const pusher = new Pusher("bcbda7a4a6c1354e5ec4", { encrypted: true, cluster: "ap3" });
		const pushChannel = pusher.subscribe(channelName);

		pushChannel.bind("message", data => {
			console.log("data", data);

			if (data.status === "done") {
				resultStatus(findPusherIndex(), "done");
			}
		});

		// 페이지 열람 후 10초 안에 응답이 없으면 실패처리
		setTimeout(
			() => {
				console.log("trigger pusher test");
				jQuery.getJSON(triggerPusherEndpoint);

				setTimeout(
					() => {
						const index = findPusherIndex();

						if (rows[index].status === "") {
							resultStatus(index, "fail");
						}
					},
					5000
				);
			},
			1000
		);

		rows.forEach((row, index) => {
			const { endpoint, type } = row;

			if (type === "") {
				return true;
			}

			const noneCacheUrl = `${endpoint}?_=${+new Date()}`;

			setTimeout(
				() => {
					switch (type) {
						case "image":
							{
								const beacon = new Image();

								beacon.onerror = () => {
									resultStatus(index, "fail");
								};

								beacon.onload = () => {
									resultStatus(index, "done");
								};

								// Attach the src for the script call
								beacon.src = noneCacheUrl;

								break;
							}
						case "js":
							{
								loadScript(
									noneCacheUrl,
									() => {
										resultStatus(index, "done");
									},
									() => {
										resultStatus(index, "fail");
									}
								);

								break;
							}
						default:
							{
								const opts = {
									url: noneCacheUrl,
									crossdispUrl: true,
									complete: (xhr, textStatus) => {
										resultStatus(index, xhr.status == 200 ? "done" : "fail");
									},
									error: () => {
										resultStatus(index, "fail");
									}
								};

								jQuery.ajax(opts);
							}
					}
				},
				(index + 1) * 300
			);
		});

		return () => {
			pusher.unsubscribe(pushChannel);
		};
	});

	return [failCount, progress, rows, trans];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document_1.getElementById("svelte-1umqyk2-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;